/* General Resets */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body, html {
    font-family: 'Arial', sans-serif;
    background-color: #FAF3E0; /* Off White */
    color: #36454F; /* Charcoal Gray */
}

/* Utility Classes */
.container {
    padding: 0 20px;
}

/* Hero Section */
.hero-section {
    background-color: #008080; /* Primary Teal */
    color: #FAF3E0; /* Off White for contrast */
    text-align: center;
    padding: 50px 20px;
}

.hero-background {
    /* Placeholder for Background Graphic */
    /* background-image: url('/path/to/your/background-graphic.jpg'); */
    background-size: cover;
    background-position: center;
}

.hero-content {
    padding: 20px;
}

.begin-journey-btn {
    background-color: #AA00FF; /* Neon Purple */
    color: #FAF3E0; /* Off White */
    margin-top: 20px;
    padding: 10px 30px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.begin-journey-btn:hover {
    background-color: #FF6F61; /* Bright Orange for hover */
}

/* Features Section */
.features-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 40px 0;
}

.feature-card {
    width: 45%;
    margin: 20px 0;
    padding: 20px;
    background-color: #FAF3E0; /* Off White */
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

/* About Mangrove Section */
.about-mangrove-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 0;
}

.about-text, .about-graphic {
    width: 48%;
}

/* Testimonials Section */
.testimonials-section {
    background-color: #032221; /* Dark Green */
    color: #FAF3E0; /* Off White */
    padding: 30px;
    text-align: center;
}

/* Footer */
.landing-footer {
    background-color: #36454F; /* Charcoal Gray */
    color: #FAF3E0; /* Off White */
    text-align: center;
    padding: 20px 0;
}

.social-media-links {
    margin-bottom: 20px;
}

.landing-footer a {
    color: #FAF3E0; /* Off White */
    text-decoration: none;
    margin: 0 10px;
}

@media (max-width: 768px) {
    .features-section, .about-mangrove-section {
        flex-direction: column;
    }

    .feature-card, .about-text, .about-graphic {
        width: 100%;
    }
}
